import React from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Iframe from "./Components/Iframe";

const iframeUrl = {
  zoho:
    '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://assist.zoho.com/login/embed-remote-support.jsp"></iframe>',
};

function App() {
  return (
    <div className="Login-body-wrapper">
      <div className="bg-image">
        <img src="./assets/images/thanku-bg.png" />
      </div>
      <div className="container-fluid">
        <div className="header-section">
          <img src="./assets/images/CSympl_Logo.png" />
        </div>
        <p className="top-message-text">Connect with CSympl</p>
        <div className="col-md-10 col-xs-12 offset-md-1">
          <div className="store-add-card">
            <div className="login-forn-content-addstore ">
              <div className="position-relative">
                <Iframe iframe={iframeUrl["zoho"]} allow="autoplay" />
                <div className="obsoulteBg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="login-page-footer-text">
        <p className="float-left">CSympl © {new Date().getFullYear()}</p>
        <p className="float-right">Powered by Object Source Inc</p>
      </div>
    </div>
  );
}

export default App;
